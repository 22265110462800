import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export const ButtonWrapper = styled(props => <Link {...props} />)`
  margin: 0.75rem 0 0 0;
  padding: 0.5rem 0.75rem;
  background-color: yellow;
  border-radius: 0.5rem;
  color: gray;
  font-weight: 300;
  font-size: 0.875rem;
  width: fit-content;
  transition: filter 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.colors.light1};
  background-color: ${props => props.theme.colors.main1};
  transition: all 0.2s;
  &:hover,
  &:focus {
    filter: brighterness(100%);
    // background-color: #357ae8;
    box-shadow: 0px 2px 5px rgba(0,1,4,0.5);
    transform: translateY(-5px);
  }
`
