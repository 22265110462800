import styled from "styled-components"

export const CardWrapper = styled.div`
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  /* by default is left to right */
  /* now is top to bottom */
  flex-direction: column;
  align-items: center;
  transition: all 0.2s;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;

  @media screen and (min-width: 768px) {
    /* padding-bottom: 50px; */
    /* padding-top: 20px; */
    &:hover,
    &:focus {
      filter: brighterness(100%);
      // background-color: #357ae8;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      transform: translateY(-5px);
    }
  }
`
