import styled from "styled-components"

export const FeatureImageWrapper = styled.div`
  /* space it takes up */
  grid-column: 3 / span 10;
  grid-row: 2 / 4;
  overflow: hidden;
  position: relative;
  border-radius: 50px;
  margin-top: 50px;
  box-shadow: 2px 0px 20px rgba(0,0,0,0.1);

  @media ${props => props.theme.breakpoints.tablet} {
    grid-column: 2 / span 6;
    
  }
`
