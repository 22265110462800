import React from "react"
// Hook
import { useStaticQuery, graphql } from "gatsby"
import { NavWrapper } from "../elements"
import styled from "styled-components"
// import logoSVG from "../img/logo.svg"
// import Tripadvisor from "./tripadvisor-logo/Tripadvisor.js"

const OfficialWebpage = styled.a`
  /* grid-column: 1 / span 12; */
  grid-row: 1/ 2;
  display: flex;
  /* margin-left: right; */
  align-items: flex-end;
  text-decoration: none;
  margin: 0 2rem;

  &:hover {
    text-decoration: underline;
  }
  @media ${props => props.theme.breakpoints.tablet} {
    grid-column: 2 / span 6;
  }
`

const CustomSpan = styled.div`
  transition: all 0.3s;

  @media screen and (min-width: 990px) {
    &:hover {
      transform: scale(1.05);
      grid-column: 2 / span 6;
      // align-self: center;
    }
  }
`

const Ul = styled.ul`
  list-style: none;
  margin-left: auto;
`
const Li = styled.li`
  margin: 20px 0 10px 0px;
 
  /* @media screen and (max-width: 990px) {
    font-size: 13px;
  } */
`

export const Nav = () => {
  const data = useStaticQuery(graphql`
    query image {
      loqo: file(relativePath: { eq: "logo.svg" }) {
        publicURL
      }
    }
  `)

  return (
    <NavWrapper>
      <CustomSpan>
        {" "}
        <a href="/" target="" rel="noopener noreferrer">
          <img
            src={data.loqo.publicURL}
            alt="Sausage KL"
            href="https://www.blog.sausagekl.com"
          />
        </a>
      </CustomSpan>

      {/* Tripadvisor logo 
      <Tripadvisor></Tripadvisor>
      */}

      <Ul>
        <Li>
          <OfficialWebpage
            href="http://www.sausagekl.com"
            s
            target="_blank"
            rel="noopener noreferrer"
          >
            Main Site
          </OfficialWebpage>
        </Li>
        <Li>
          {" "}
          <OfficialWebpage
            href="https://www.tripadvisor.com.my/Restaurant_Review-g298570-d15042368-Reviews-Sausage_KL_Cafe_Deli-Kuala_Lumpur_Wilayah_Persekutuan.html/#REVIEWS"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tripadvisor
          </OfficialWebpage>
        </Li>
      </Ul>
    </NavWrapper>
  )
}

// <img src={data.loqo.publicURL} alt="logo" />
