import React from 'react';
import { PaginationWrapper, PaginationElement } from '../elements';

export const Pagination = ({isFirst, isLast, prevPage, nextPage}) => {
  return (
    <PaginationWrapper isFirst={isFirst} isLast={isLast}>
      {/* <PaginationElement to={prevPage}>{isLast || !isFirst? "Previous Page" : ""}</PaginationElement>
      <PaginationElement to={nextPage}>{isFirst || !isLast? "Next Page" : ""}</PaginationElement> */}
        <PaginationElement to={prevPage}>&larr; Newer Posts</PaginationElement>
      <PaginationElement to={nextPage}>Older Posts &rarr;</PaginationElement>
    </PaginationWrapper>
  )
}