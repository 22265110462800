import React from "react"

import styled from "styled-components"
// import "./styles.scss"

const CustomDiv = styled.div`
  /* margin-top: 1rem; */
  align-self: center;
  display: flex;
  /* display: flex; */
  /* justify-content: center; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 50px;
  transition: all 0.3s;
  pointer-events: none;
  @media screen and (min-width: 990px) {
    &:hover {
      transform: scale(1.05);
      grid-column: 2 / span 6;
    }
  }
`

const Tripadvisor = () => {
  return (
    <CustomDiv>
      <script
        sync
        src="https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=387&amp;locationId=15042368&amp;lang=en_MY&amp;year=2020&amp;display_version=2"
        data-loadtrk
        onload="this.loadtrk=true"
      ></script>
      <div
        id="TA_certificateOfExcellence387"
        class="TA_certificateOfExcellence"
      >
        <a
          href="https://www.tripadvisor.com.my/Restaurant_Review-g298570-d15042368-Reviews-Sausage_KL_Cafe_Deli-Kuala_Lumpur_Wilayah_Persekutuan.html/#REVIEWS"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://www.tripadvisor.com.my/img/cdsi/img2/awards/v2/tchotel_2020_LL-14348-2.png"
            alt="TripAdvisor"
            class="widCOEImgChat"
            id="CDSWIDCOELOGO"
          />
        </a>
      </div>
    </CustomDiv>
  )
}

export default Tripadvisor

// <div id="TA_certificateOfExcellence387" class="TA_certificateOfExcellence">
//         <ul id="5OiIjM" class="TA_links QMZZUEAVT9c">
//             <li id="qENbpsQcU" class="bQpWVjOoHuYE"><a target="_blank"
//                     href="https://www.tripadvisor.com.my/Restaurant_Review-g298570-d15042368-Reviews-Sausage_KL_Cafe_Deli-Kuala_Lumpur_Wilayah_Persekutuan.html"><img
//                         src="https://www.tripadvisor.com.my/img/cdsi/img2/awards/v2/tchotel_2020_LL-14348-2.png"
//                         alt="TripAdvisor" class="widCOEImg" id="CDSWIDCOELOGO" /></a></li>
//         </ul>
//     </div>
//     <script async
//         src="https://www.jscache.com/wejs?wtype=certificateOfExcellence&amp;uniq=387&amp;locationId=15042368&amp;lang=en_MY&amp;year=2020&amp;display_version=2"
//         data-loadtrk onload="this.loadtrk=true"></script>
