import styled from "styled-components"

export const ContentWrapper = styled.main`
  grid-column: 4 / span 8;
  grid-row: 3 / span 5;
  background-color: ${props => props.theme.colors.light1};
  border-radius: 50px;
  margin-top: 40px;
  transition: all 0.5s;
  /* box-shadow: ${props => props.theme.shadows.shadow1}; */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;

  @media screen and (min-width: 768px) {
    /* padding-bottom: 50px; */
    /* padding-top: 20px; */
    &:hover,
    &:focus {
      filter: brighterness(100%);
      // background-color: #357ae8;
      box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.2);
      transform: translateY(-5px);
    }
  }

  padding: ${props =>
    `${props.theme.spacings.xLarge} ${props.theme.spacings.xxLarge}`};

  @media ${props => props.theme.breakpoints.tablet} {
    padding: ${props =>
      `${props.theme.spacings.xLarge} ${props.theme.spacings.xxLarge}`};
    grid-column: 2 / span 6;
  }

  @media ${props => props.theme.breakpoints.mobile} {
    padding: ${props =>
      `${props.theme.spacings.medium} ${props.theme.spacings.large}`};
  }
`
