import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  FooterWrapper,
  FooterSocialIcons,
  FooterSocialWrapper,
  P,
} from "../elements"
// import facebookSVG from "../img/facebook.svg";
// import instagramSVG from "../img/instagram.svg";
import Tripadvisor from "./tripadvisor-logo/Tripadvisor.js"

export const Footer = () => {

  const data = useStaticQuery(graphql`
    query {
      facebook: file(relativePath: {eq: "facebook.svg"}) {
        publicURL
      }
      instagram: file(relativePath: {eq: "instagram.svg"}) {
        publicURL
      }
    }
  `)

  return (
    <FooterWrapper>
      <FooterSocialWrapper>
        <Tripadvisor/>
        <FooterSocialIcons>
          <a href="https://www.facebook.com/sausagekl/" target="_blank" rel="noopener noreferrer">
            <img src={data.facebook.publicURL} alt="Facebook logo"/>
          </a>
          <a href="https://www.instagram.com/sausagekl" target="_blank" rel="noopener noreferrer">
            <img src={data.instagram.publicURL} alt="Instagram logo"/>
          </a>
        </FooterSocialIcons>
        <P size="xSmall" color="dark3"><span role="img" aria-label="Copyright">Ⓒ</span> Sausage KL Café & Deli {new Date().getFullYear()}{" "}<span role="img" aria-label="Love">❤️</span></P>
      </FooterSocialWrapper>
    </FooterWrapper>
  )
}
